<template>
  <v-app class="background">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'GuestLayout'
};
</script>

<style scoped>
.background {
  background: url('../assets/Arcelik_Bg_Giris_Rev.png') no-repeat center center !important;
  background-size: cover !important;
}
</style>
